import React from 'react'
import { CFView, DefaultScreen, MobileScreen } from 'components'
import { contactbg, mobileContactbg } from 'images'
import RestaurantDetails from './RestaurantDetails'
import Hours from './Hours'
import Map from './Map'
import SocialMedia from './SocialMedia'
import DownloadApp from './DownloadApp'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <Map />
          <CFView
            column
            center
            w="100%"
            pv="30px"
            mt="-5px"
            image={`url(${mobileContactbg}) top / cover no-repeat`}
          >
            <CFView w="100%" column center>
              <RestaurantDetails />
              <Hours />
              <SocialMedia />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center>
          <Map />
          <CFView
            mt="-5px"
            w="100%"
            pv="50px"
            row
            justifyEnd
            alignCenter
            image={`url(${contactbg}) center / cover no-repeat`}
          >
            <CFView w="100%" row justifyAround maxWidth="800px" ml="50px">
              <CFView column>
                <RestaurantDetails />
                <SocialMedia />
              </CFView>
              <Hours />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
