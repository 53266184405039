import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center></CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          alignCenter
          h="600px"
          w="100%"
          image={`url(${about}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
